import Modal from '@/Components/Common/Modal.jsx';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import * as React from 'react';
import {forwardRef, useState} from 'react';

const ConfirmModal = forwardRef(
    (
        {
            open,
            onClose,
            onConfirm,
            title,
            description,
            children,
            confirmText = 'Confirmer',
            cancelText = 'Annuler',
            isForm = false,
            confirmButtonProps = {},
            cancelButtonProps = {},
            ...props
        },
        ref,
    ) => {
        const [loading, setLoading] = useState(false);

        const handleConfirm = async () => {
            setLoading(true);
            if (onConfirm) {
                await onConfirm();
            }
            setLoading(false);
        };

        const handleClose = async () => {
            setLoading(true);
            if (onClose) {
                await onClose();
            }
            setLoading(false);
        };
        return (
            <Modal
                ref={ref}
                open={open}
                onClose={handleClose}
                title={title}
                description={description}
                actions={
                    <>
                        <LoadingButton
                            variant="text"
                            color="text"
                            size="large"
                            startIcon={<CloseIcon />}
                            loading={cancelButtonProps?.loading || loading}
                            loadingPosition="start"
                            onClick={handleClose}
                            {...cancelButtonProps}
                        >
                            <span>{cancelText}</span>
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<CheckIcon />}
                            loading={cancelButtonProps?.loading || loading}
                            loadingPosition="start"
                            {...(isForm ? {type: 'submit'} : {onClick: handleConfirm})}
                            {...confirmButtonProps}
                        >
                            <span>{confirmText}</span>
                        </LoadingButton>
                    </>
                }
                isForm={isForm}
                onSubmit={onConfirm}
                {...props}
            >
                {children}
            </Modal>
        );
    },
);

export default ConfirmModal;
